import React from 'react';
import ErrorComponent from '@/components/common/ErrorComponent';

export default function Custom404() {
  return (
    <ErrorComponent
      description="Oops! The page you are looking for seems to be missing or may have been moved. We apologize for the inconvenience."
      title="Oops!"
      type="404"
    >
      <p className="max-w-sm text-center text-primary-main">
        If you believe you should have access or need any assistance, please
        reach out to our team on Twitter at{' '}
        <a
          className="text-white"
          href="https://twitter.com/OverpassIP"
          target="_blank"
          rel="noreferrer"
        >
          @OverpassIP.
        </a>
      </p>
    </ErrorComponent>
  );
}
